import { useContext, useEffect } from 'react';
import React, { useRef } from 'react';
import emailjs from '@emailjs/browser';
import { Bounce, toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useState } from "react";
import ReactGA from "react-ga4";

import classes from './ContactForm.module.css';
import ThankYouMessage from './ThankYouMessage';

export const ContactForm = () => {
    const [show, setShow] = useState(false);
    const [formValues, setFormValues] = useState({
        name: "",
        email: "",
        message: ""
    });
    
    useEffect(()=> {
        ReactGA.send({ hitType: "pageview", page: "/", title: "contact form" });
    },[]);

    const form = useRef();
    const sendEmail = (e) => {
      e.preventDefault();
      setShow(!show);
      
      setFormValues({
        name: "",
        email: "",
        message: ""
      });
  
      emailjs.sendForm('service_el1v295', 'template_2smhsep', form.current, 'ATkq-YAbNHVXk7Enn')
        .then((result) => {
            console.log(result.text);
            ReactGA.event({
                category: "form submission",
                action: "send",
                label: "", // optional
                value: 0, // optional, must be a number
                nonInteraction: true, // optional, true/false
                transport: "hr", // optional, beacon/xhr/image
              });
            
        }, (error) => {
            console.log(error.text);
            toast.failed("There was an error with your submission")
        });
    };
  
    return (
        <form ref={form} onSubmit={sendEmail}>
            <div className={classes.parent}>
                <div className={classes.child}>
                    <h1 className={classes.heading}>Contact Us!</h1>
                    <p className={classes.paragraph}> Downtowns are the hubs where magic happens, we’re the digital version.</p>
                    <p className={classes.paragraph}> With a breadth of experience across multiple industries and verticals, 
                    your digital solution to that pressing business challenge can be found with <strong>Downtown</strong>. 
                    If it is used by humans via a screen, voice or gestures, let's talk. 
                    </p>
                    <p>We’re excited to hear about your needs, so send us a message using our form or shoot over an email at: <span className={classes.underline}>team@hellodowntown.co</span></p>
                    <br/>
                </div>
                <div className={classes.child}>
                    {!show && (
                        <><div className={classes.control}>
                            <input value={formValues.name} onChange={(e) => setFormValues({ ...formValues, name: e.target.value })} className={classes.control} type='name' placeholder='Name' required id='name' name='name' />
                        </div><div className={classes.control}>
                                <input value={formValues.email} onChange={(e) => setFormValues({ ...formValues, email: e.target.value })} className={classes.control} type='email' placeholder='Email' required id='email' name='email' />
                            </div><div className={classes.control}>
                                <textarea value={formValues.message} onChange={(e) => setFormValues({ ...formValues, message: e.target.value })} className={classes.control} placeholder='Message' required id='message' name='message'></textarea>
                            </div><div className={classes.control}>
                                <button>Send</button>
                            </div></>
                    )}
                    {show && <ThankYouMessage />}
                </div>
            </div>
            <ToastContainer
                position='bottom-center'
                autoClose={3000}
                transition={Bounce}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme='dark' />
        </form> 
    );      
};

export default ContactForm;