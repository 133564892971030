import logo from './assets/logo.png';
import './App.css';
import ContactForm from './components/ContactForm';
import LogoGrid from './components/LogoGrid';
import DownArrow from './ui/DownArrow';
import ReactGA from 'react-ga4';

// const TRACKING_ID = "UA-285519692-1";   
const TRACKING_ID = "G-4E2DTKM69P";     
ReactGA.initialize(TRACKING_ID);

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <p></p>
        <img src={logo} className="App-logo" alt="logo" />
        <h1 className='header'><b>A strategic technology partner for your business.</b></h1>
      </header>
        <DownArrow />
        <LogoGrid />
        <ContactForm />
    </div>
  );
}

export default App;
