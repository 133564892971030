import classes from './LogoGrid.module.css';

function LogoGrid()
{
    const desktopImage = './images/background-line-2.png';
    // const tabletImage =  './images/background-tablet-line.png';
    const mobileImage = './images/background-mobile-line-2.png';

    const imageUrl = window.innerWidth >= 1180 ? desktopImage : mobileImage;
    console.log(window.innerWidth);
      
    
    const lineStyle ={
        backgroundImage:`url(${imageUrl})`,
        backgroundSize: '100%',
        backgroundRepeat: 'no-repeat'
    };
    
    return (
        <><section id='form'> </section>
        <div className={classes.logogrid} style={lineStyle}>
            <h1 className={classes.heading}> Experience</h1>
            <div className={classes.container}>
                <div><img className={classes.image} src='./images/fx.png' alt='arrow' /></div>
                <div><img className={classes.image} src='./images/nike.png' alt='arrow' /></div>
                <div><img className={classes.image} src='./images/beatnic.png' alt='arrow' /></div>
                <div><img className={classes.image} src='./images/sweetgreen.png' alt='arrow' /></div>
                <div><img className={classes.image} src='./images/freethework.png' alt='arrow' /></div>
                <div><img className={classes.image} src='./images/necessaire.png' alt='arrow' /></div>
                <div><img className={classes.image} src='./images/sonos.png' alt='arrow' /></div>
                <div><img className={classes.image} src='./images/katefarms.png' alt='arrow' /></div>
                <div><img className={classes.image} src='./images/loom.png' alt='arrow' /></div>
            </div>
        </div></>
    );
}
export default LogoGrid;