import React from "react";
import classes from './ThankYouMessage.module.css';

const ThankYouMessage = () => {
  return (
    <div className={classes.thankyou}>
      <div>We'll be intouch soon, thank you!</div>
    </div>
  );
};

export default ThankYouMessage;
